<template>
  <div data-testid="container-downloads">
    <v-card>
      <v-toolbar>
        <v-card-title>Downloads</v-card-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <div
          class="downloads-content"
          v-if="downloadPage"
          v-html="downloadPage.content"
          data-testid="content-downloads"
        ></div>
        <p v-if="!downloadPage" class="text-center">
          Currently no available downloads
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  created() {
    this.$store.dispatch("pagesList")
  },

  computed: {
    ...mapGetters({
      pagesList: "pagesList",
    }),

    downloadPage() {
      return this.pagesList.length > 0 ? this.pagesList[0] : null
    },
  },
}
</script>

<style lang="scss">
.downloads-content {
  padding: 0 20px;
}
</style>
